
























































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },
  setup(_, { root }) {
    const model = reactive({
      receivePostersTo: "",
      editPostersTo: "",
      languageVersion: "polish",
      textPl: "",
      textEn: "",
      file: null as any,
      personalData: "",
      privacyPolicy: "",
    });
    const state = reactive({
      isValid: false,
      loading: false,
      error: false as boolean | number,
      addedFile: null as any,
    });

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const getConfig = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/poster-session-module`)
        .then(({ data }) => {
          model.receivePostersTo = new Date(data.receivePostersTo)
            .toISOString()
            .slice(0, 10);
          model.editPostersTo = new Date(data.editPostersTo)
            .toISOString()
            .slice(0, 10);
          model.languageVersion = data.languageVersion;
          model.textPl = data.textPl;
          model.textEn = data.textEn;
          state.addedFile = data.file;
          model.personalData = data.personalData;
          model.privacyPolicy = data.privacyPolicy;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(getConfig);

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const formData = new FormData();
      if (model.file) formData.append("file", model.file);
      formData.append("isEnabled", "true");
      formData.append("receivePostersTo", model.receivePostersTo);
      formData.append("editPostersTo", model.editPostersTo);
      formData.append("languageVersion", model.languageVersion);
      formData.append("textPl", model.textPl);
      formData.append("textEn", model.textEn);
      formData.append("personalData", model.personalData);
      formData.append("privacyPolicy", model.privacyPolicy);

      axiosInstance
        .put(`event/${root.$route.params.id}/poster-session-module`, formData, {
          headers: {
            event: root.$route.params.id,
          },
          params: {
            fileType: "posterSessionModule",
          },
        })
        .then(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("panel.event.posterSession.success"),
          });
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        });
    };

    const getFile = (name: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`file/${name}`, { responseType: "blob" })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          console.log(fileURL);
          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${name}`);
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        })
        .catch((e) => console.log(e));
    };
    return { model, state, rules, onSubmit, getFile };
  },
});
